<template>
  <div>
    <page-component
      pageTitle="Count History"
      :headers="headers"
      :items="counts"
      :loadingData="loading"
      sortDesc
      :buttons="[{ text: '+ Count New Inventory', to: '/count/AddEditForm' }]"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ getCountDate(item.date) }}
        <br />
        <span style="color: var(--v-lighttext-base)">
          {{ getCountTime(item.date) }}
        </span>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        {{ getUserDisplayName(item.userId) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="eye" @click="viewCount(item)" />
      </template>
    </page-component>
    <review
      v-if="selectedCountId"
      historyView
      :date="getDateLabel(selectedCount.date)"
      :countItems="selectedCount.countItems"
      :inventoryItems="items"
      :comments="selectedCount.comment"
    />
  </div>
</template>

<script>
import PageComponent from "../../shared/PageComponent.vue"
import Review from "./Review"
import { mapState, mapGetters } from "vuex"
import { getDocs } from "firebase/firestore"
import "firebase/compat/firestore"
import moment from "moment"

export default {
  name: "count-history",
  components: { PageComponent, Review },
  data() {
    return {
      loading: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Date", value: "date" },
        { text: "User", value: "user" },
        { text: "Actions", value: "actions" },
      ],
      counts: [],
    }
  },
  computed: {
    ...mapGetters(["getUnitName"]),
    ...mapState(["restaurants", "firebaseRefs", "users", "items"]),
    selectedCountId() {
      return this.$route.params.id
    },
    selectedCount() {
      return this.counts.find(count => count.id === this.selectedCountId)
    },
  },
  watch: {
    "$route.params.tab": function (tab) {
      if (tab === "history") {
        this.loading = true
        setTimeout(() => {
          this.setup()
        }, 1000)
      }
    },
  },
  async mounted() {
    await this.setup()
  },
  methods: {
    async setup() {
      this.loading = true
      const countSnapshot = await getDocs(this.firebaseRefs.countsRef)
      this.counts = countSnapshot.docs
        .filter(doc => doc.data() && !doc.data().isDeleted)
        .map(doc => {
          return { ...doc.data() }
        })
      this.loading = false
    },
    getUserDisplayName(userId) {
      if (this.users) {
        const userDoc = Object.values(this.users)?.find(u => u.userId === userId)
        if (userDoc?.name) return userDoc.name
      }
      return "N/A"
    },
    getCountDate(date) {
      return moment(date.toDate()).format("MMM DD, YYYY")
    },
    getCountTime(date) {
      return moment(date.toDate()).format("h:mm A")
    },
    getDateLabel(date) {
      return moment(date.toDate()).format("YYYY-MM-DD HH:mm:ss")
    },
    viewCount(count) {
      this.$router.push({ params: { form: "Review", id: count.id } })
    },
  },
}
</script>
