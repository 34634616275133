<template>
  <div>
    <page-component
      pageTitle="Count New Inventory"
      :headers="headers"
      :items="filteredItems"
      :loading="loading"
      hide-filter-toggle
    >
      <template v-slot:filters[0]>
        <custom-date-picker
          v-model="date"
          :label="date ? 'Date' : 'Select Date'"
          dense
          init
          showTime
        />
      </template>
      <template v-slot:filters[1]>
        <custom-select
          v-model="selectedStorage"
          :items="[{ id: '', name: 'All Storages' }, ...storages]"
          item-text="name"
          item-value="id"
          label="Storage"
        />
      </template>
      <template v-slot:filters[2]>
        <custom-select
          v-model="selectedSubcategory"
          :items="[{ id: '', name: 'All Subcategories' }, ...subcategories]"
          item-text="name"
          item-value="id"
          label="Subcategory"
        />
      </template>
      <template v-slot:[`item.caseSize`]="props">
        <span v-if="props.item.caseSize">
          {{ props.item.caseSize }} pkg{{ props.item.caseSize != 1 ? "s" : "" }}
          <span v-if="props.item.packageMeasurement" class="text--lighttext">
            <br />
            {{ getCaseMeasurementWithUnit(props.item) }}
          </span>
        </span>
      </template>
      <template v-slot:[`item.packageSize`]="props">
        <span v-if="props.item.packageMeasurement">
          {{ getPackageMeasurementWithUnit(props.item) }}
        </span>
      </template>
      <template v-slot:[`item.quantity`]="props">
        <package-or-case-field v-model="props.item" @input="updateQuantity()" />
      </template>
      <template v-slot:[`item.measurement`]="props">
        <span v-if="props.item.measurement">
          {{ getMeasurementForDisplay(props.item.measurement) }}
        </span>
      </template>
      <template v-slot:[`item.totalSize`]="props">
        <span v-if="props.item.packageMeasurement && props.item.quantity">
          &asymp; {{ getTotalQuantityWithUnit(props.item) }}
        </span>
      </template>
      <template v-slot:[`item.storage`]="props">
        <custom-select
          v-model="props.item.storage"
          label=""
          :items="storages"
          item-text="name"
          item-value="id"
          dense
          outlined
          hide-details
          :disabled="props.item.quantity === undefined"
          @input="updateQuantity()"
        />
      </template>
    </page-component>
    <v-footer fixed elevation="3" class="fixed-btn-container py-4">
      <v-btn
        :disabled="countedItems.length === 0"
        color="primary"
        class="fixed-btn"
        :to="{ params: { form: 'Review' } }"
      >
        Review
      </v-btn>
    </v-footer>
    <review :date="date" :countItems="countedItems" @success="closeReview" />
  </div>
</template>

<script>
import PageComponent from "../../shared/PageComponent.vue"
import Review from "./Review"
import CustomDatePicker from "../../shared/forms/datetime/CustomDatePicker.vue"
import PackageOrCaseField from "../../shared/forms/PackageOrCaseField"
import CustomSelect from "../../shared/CustomSelect.vue"
import topLevelMixins from "../../mixins.js"
import { mapState, mapGetters } from "vuex"
import moment from "moment"

export default {
  name: "count-new",
  components: {
    PageComponent,
    Review,
    CustomDatePicker,
    PackageOrCaseField,
    CustomSelect,
  },
  mixins: [topLevelMixins],
  data() {
    return {
      loading: false,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      selectedSubcategory: "",
      selectedStorage: "",
      headers: [
        { text: "Item ID", value: "itemID" },
        { text: "Name", value: "name" },
        { text: "Case Size", value: "caseSize" },
        { text: "Package Size", value: "packageSize" },
        { text: "System Quantity", value: "measurement" },
        { text: "Counted Quantity", value: "quantity", width: 300 },
        { text: "", value: "totalSize", width: 200 },
        { text: "Storage", value: "storage" },
      ],
      itemsForCount: [],
      countedItems: [],
    }
  },
  computed: {
    ...mapGetters(["getUnitName"]),
    ...mapState(["subcategories", "supplierItems", "storages", "items"]),
    filteredItems() {
      return this.itemsForCount?.filter(
        item =>
          (!this.selectedSubcategory ||
            item?.subcategory == this.selectedSubcategory) &&
          (!this.selectedStorage || item?.storage == this.selectedStorage)
      )
    },
  },
  async created() {
    this.loading = true
    this.itemsForCount = this.items.map(item => {
      const supplierItem = this.supplierItems.find(
        sItem => sItem.linkedItemID === item.itemID
      )
      return { ...item, ...supplierItem }
    })

    this.itemsForCount.forEach(item => {
      if (!item.quantityUnit) {
        this.$set(item, "quantityUnit", "pkgs")
      }
    })
    await this.updateQuantity()
    this.loading = false
  },
  methods: {
    updateQuantity() {
      this.countedItems = JSON.parse(JSON.stringify(this.itemsForCount))
        .filter(sItem => sItem.quantity?.length)
        .map(sItem => {
          if (sItem.packageCost) {
            sItem.costMoney.amount = this.getUnitCostFromPackageCost(
              sItem.packageCost,
              sItem.packageSize,
              sItem.supplierUnit
            )
            delete sItem.packageCost
          }
          return sItem
        })
      // this.countedItems = this.countedItems.filter(item => item.quantity > 0)
    },
    closeReview() {
      // handle closing of the review form (reset all quantity and packageCost values)
      this.filteredItems.forEach(item => {
        delete item.quantity
        delete item.quantityUnit
      })
      this.$router.push({ params: { tab: "history", form: null, id: null } })
    },
  },
}
</script>
