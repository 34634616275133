<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'Review'"
      :title="historyView ? 'Inventory Count Details' : 'Review and Submit Count'"
      :showFormTitle="false"
      :closeDisabled="awaitingResponse"
      :awaitingResponse="awaitingResponse"
      :submitDisabled="awaitingResponse || historyView"
      :showSubmit="!historyView"
      @close="$router.push({ params: { form: null, id: null } })"
      @submit="submitHandle"
    >
      <v-row>
        <v-col cols="6">
          <v-list flat dense color="transparent" class="pa-0">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>Date:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ formattedDate }}
                  @
                  {{ formattedTime }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>Submitted by:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ userDisplayName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-textarea
            v-if="!historyView"
            v-model="commentToSave"
            class="mt-2"
            label="Comments"
            outlined
            rows="3"
          />
          <v-list v-else flat dense color="transparent" class="pa-0">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>Comments:</v-list-item-title>
                <div>{{ comment }}</div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="countItems"
        color="secondary"
        class="my-4"
        disable-pagination
        hide-default-footer
      >
        <template v-if="!historyView" v-slot:[`item.prevMeasurement`]="{ item }">
          {{ getPreviousQuantity(item) }}
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          {{ getNewQuantity(item) }}
        </template>
        <template v-slot:[`item.storage`]="{ item }">
          <div v-if="item.storage">
            {{ getStorageNameForItem(item) }}
          </div>
        </template>
      </v-data-table>
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import topLevelMixins from "../../mixins.js"
import { mapState, mapGetters } from "vuex"
import { doc, writeBatch, runTransaction, Timestamp } from "firebase/firestore"
import db from "../../../firebaseConfig"
import { getAuth } from "firebase/auth"
import moment from "moment"

export default {
  name: "review-form",
  components: { FormDialog },
  mixins: [topLevelMixins],
  props: {
    date: {
      type: String,
      required: true,
    },
    countItems: {
      type: Array,
      required: true,
    },
    comment: {
      type: String,
      required: false,
      default: "",
    },
    historyView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commentToSave: null,
      awaitingResponse: false,
    }
  },
  computed: {
    ...mapGetters(["getSupplierName", "getUnitName"]),
    ...mapState([
      "firebaseRefs",
      "currentUserAccess",
      "users",
      "orderToReceive",
      "units",
      "supplierItems",
      "storages",
      "items",
    ]),
    headers() {
      const headers = [
        { text: "Item ID", value: "itemID" },
        { text: "Name", value: "name" },
        { text: "Prev Count", value: "prevMeasurement" },
        { text: "New Count", value: "quantity" },
        { text: "Storage", value: "storage" },
      ]
      if (this.historyView) {
        headers.splice(
          headers.findIndex(item => item.value === "prevMeasurement"),
          1
        )
      }
      return headers
    },
    formattedDate() {
      return this.date
        ? moment(this.date, "YYYY-MM-DD HH:mm:ss").format("MMM DD, YYYY")
        : ""
    },
    formattedTime() {
      return this.date
        ? moment(this.date, "YYYY-MM-DD HH:mm:ss").format("h:mm a")
        : ""
    },
    userDisplayName() {
      const user = getAuth().currentUser
      if (!user) return "Anonymous"
      if (user?.displayName) return user.displayName
      else if (this.users) {
        const userDoc = Object.values(this.users)?.find(u => u.userId === user.uid)
        if (userDoc?.name) return userDoc.name
      }
      return user.email
    },
    dateToSave() {
      return this.date
        ? Timestamp.fromDate(moment(this.date, "YYYY-MM-DD HH:mm:ss").toDate())
        : null
    },
  },
  methods: {
    submitHandle: async function () {
      if (this.awaitingResponse || this.historyView) return

      this.awaitingResponse = true
      try {
        // Start a Firestore transaction
        await runTransaction(db, async transaction => {
          const batch = writeBatch(db)

          // Create counts entry
          const countId = doc(this.firebaseRefs.countsRef).id
          const counts = {
            id: countId,
            date: this.dateToSave,
            userId: this.currentUserAccess?.userId,
            comment: this.commentToSave,
            countItems: this.countItems,
          }
          batch.set(doc(this.firebaseRefs.countsRef, countId), counts)

          const currentItemQuantities = []
          for (let i = 0; i < this.countItems.length; i++) {
            const itemDocRef = doc(
              this.firebaseRefs.itemsRef,
              this.countItems[i].itemID
            )
            const itemDoc = await transaction.get(itemDocRef)
            currentItemQuantities[i] =
              itemDoc.data().packageMeasurement?.quantity || 0
          }

          // Handle changes to inventory items, supplier items, and inventory item logs
          for (let i = 0; i < this.countItems.length; i++) {
            const itemDocRef = doc(
              this.firebaseRefs.itemsRef,
              this.countItems[i].itemID
            )
            const prevQuantity = currentItemQuantities[i] || 0
            const quantity =
              parseFloat(this.countItems[i].quantity) *
              this.getQuantityOfPackageGrouping(this.countItems[i])

            // Update quantity and storage of inventory item
            transaction.update(itemDocRef, {
              measurement: {
                quantity: quantity,
                unit: this.countItems[i].packageMeasurement.unit,
                preferredDisplayUnitId:
                  this.countItems[i].packageMeasurement.preferredDisplayUnitId,
              },
              storage: this.countItems[i].storage,
            })

            // Add the inventory item log entry
            const inventoryItemLogId = doc(this.firebaseRefs.inventoryitemslogRef).id
            batch.set(
              doc(this.firebaseRefs.inventoryitemslogRef, inventoryItemLogId),
              {
                id: inventoryItemLogId,
                itemID: this.countItems[i].itemID,
                countId: countId,
                prevMeasurement: {
                  quantity: prevQuantity,
                  unit: this.countItems[i].packageMeasurement.unit,
                  preferredDisplayUnitId:
                    this.countItems[i].packageMeasurement.preferredDisplayUnitId,
                },
                measurement: {
                  quantity: quantity,
                  unit: this.countItems[i].packageMeasurement.unit,
                  preferredDisplayUnitId:
                    this.countItems[i].packageMeasurement.preferredDisplayUnitId,
                },
                delta: quantity - prevQuantity,
                date: this.dateToSave,
                triggerType: "Count",
                costMoney: this.countItems[i].costMoney,
              }
            )
          }

          // Commit the batch
          await batch
            .commit()
            .then(() => {
              this.awaitingResponse = false
              this.$emit("success")
            })
            .catch(e => {
              this.awaitingResponse = false
              console.error("Error: ", e)
            })
        })
      } catch (e) {
        this.awaitingResponse = false
        console.error("Error: ", e)
      }
    },
    getAssociatedSupplierItem(itemId) {
      if (!itemId || !this.supplierItems.length) return
      return this.supplierItems.find(supplierItem => supplierItem.itemID === itemId)
    },
    getQuantityOfPackageGrouping(item) {
      return item.quantityUnit === "cases"
        ? item.packageMeasurement?.quantity * item.caseSize
        : item.packageMeasurement?.quantity
    },
    getPreviousQuantity(item) {
      return (
        this.getQuantityInBaseUnits(this.getQuantity(item), item.itemID) +
        " " +
        this.getUnitName(
          item.packageMeasurement.preferredDisplayUnitId,
          item.quantity
        )
      )
    },
    getNewQuantity(item) {
      return (
        parseFloat(item.quantity) *
          parseFloat(
            this.getQuantityInBaseUnits(
              this.getQuantityOfPackageGrouping(item),
              item.itemID
            )
          ) +
        " " +
        this.getUnitName(item.packageMeasurement.preferredDisplayUnitId) +
        "s"
      )
    },
    getQuantity(countItem) {
      if (this.items && this.items.length > 0)
        return (
          this.items.find(i => i.itemID === countItem.itemID)?.measurement
            ?.quantity || 0
        )
    },
    getBaseUnit(itemId) {
      if (!itemId || !this.supplierItems.length) return
      return this.units.find(
        u => u.id === this.getAssociatedSupplierItem(itemId).supplierUnit
      ).base
    },
    getStorageNameForItem(item) {
      if (!item.storage) return "N/A"
      return (
        this.storages.find(storage => storage.id === item.storage)?.name ||
        "[Unknown Storage]"
      )
    },
  },
}
</script>

<style scoped lang="scss">
.v-data-table tbody tr:last-child {
  background-color: var(--v-primary-base) !important;
  color: var(--v-text-on-primary-bg-base) !important;
  font-weight: bold;
}
</style>
